@import "../styles/variables";
.indexPage {
  height: 100%;
  display: flex;
  flex-direction: column;
  .header {
    flex: 0 0 auto;
  }

  .container {
    width: 100%;
    height: calc(100% - 40px);
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    & > div {
      height: 100%;
    }
  }
  .lessonsNavigation {
    background-color: $color-white;
    flex: 3 0 0;

    @include desktop {
      min-width: 290px;
      max-width: 350px;
    }

    @include mobile {
      display: none;
      width: 100%;
    }
  }
  .content {
    height: calc(100% - 40px);
    overflow: auto;
    padding: 10px 18px;
    //background: #ccc;
    flex: 7.5 0 0;
  }
}

.mobileShow {
  display: flex !important;
}
.mobileHidden {
  @include mobile {
    display: none !important;
  }
}
