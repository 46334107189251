@import "../../styles/variables";
.settings {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .item {
    padding: 0 15px;
    cursor: pointer;
    &:hover {
      color: darken($color-white, 15);
    }
  }
  .mobileHidden {
    @include mobile {
      display: none;
    }
  }
  .mobileShow {
    display: none;
    @include mobile {
      display: block;
    }
  }
}
