@import "../../styles/variables";

.switch {
  position: relative;
  flex: 3 0 auto;
  & > * {
    direction: ltrs;
  }
  display: inline-block;
  width: 40px;
  height: 24px;
  transform: rotate(180deg);
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 4px;
  bottom: 5px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: $color-orange;
}

input:focus + .slider {
  box-shadow: 0 0 1px $color-orange;
}

input:checked + .slider:before {
  transform: translateX(18.5px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
