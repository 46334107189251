@import "../styles/buttons";
@import "../styles/variables";
.statusMessageContainer {
  width: 100%;
  height: 100%;
  display: flex;
  .statusMessage {
    text-align: center;
    margin: auto;
    i {
      color: $color-orange;
      font-size: 140px;
      margin-bottom: 5px;
    }
    h1 {
      color: $color-aqua;
    }
    h3,
    h4 {
      margin-top: 5px;
      color: $color-dark-grey;
      font-weight: weight(normal);
    }
    .error {
      color: red;
    }
    .buttons {
      margin-top: 20px;
      button {
        min-width: 150px;

        @include extra-rounded;
      }
      & > button {
        &:not(:last-child) {
          margin-left: 10px;
        }
      }
    }
  }
}
