@import "../../styles/variables";
.lessonContent {
  .titles {
    color: $color-black-blue;
    .mainTitleContainer {
      display: flex;
      flex-wrap: wrap;
      .seperator {
        color: $color-2-grey;
      }
      svg {
        fill: $color-orange;
      }
      .chapterName {
        font-size: 15px;
        color: $color-orange;
        margin-right: 4px;
      }
    }
    .lessonDescription {
      color: $color-light-grey;
      font-weight: weight(normal);
    }
    margin-bottom: 25px;
  }

  .videoContainer {
    max-width: 1300px;
    margin: auto;

    & > div {
      border-radius: 9px;
      //aspect-ratio: 16/9;
    }
    // video {
    //   padding-top: 56.25%;
    // }
  }

  .lessonFiles {
    text-align: center;
    color: $color-black-blue;
    margin-top: 15px;
    .files {
      overflow: auto;
      margin-top: 5px;
      display: flex;
      justify-content: center;
      align-items: stretch;
      flex-flow: wrap;
    }
  }
}
