@import url(https://fonts.googleapis.com/earlyaccess/opensanshebrew.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css);
@import "../styles/variables";
html,
body,
#root,
.App {
  direction: rtl;
  background-color: $color-white-blue;
  height: 100%;
}
html {
  --plyr-color-main: #1bcec1;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;

  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
    Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  align-items: center;
  font-family: "Open Sans Hebrew";
}

.heavy {
  font-weight: 800;
}
.bold {
  font-weight: 600;
}
.light {
  font-weight: 200;
}

a {
  color: inherit;
  text-decoration: none;
}

.content {
  width: 90%;
  margin: auto;
  max-width: 1200px;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
