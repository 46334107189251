@import "../styles/variables";

.header {
  background-color: $color-purple;
  width: 100%;
  //position: sticky;
  top: 0px;
  box-shadow: 0px 0 22px 0 rgba(0, 0, 0, 0.25);
  font-size: 18px;
  color: $color-white;

  .container {
    display: flex;
    .buttons {
      @include desktop {
        flex: 7.5 0;
      }
      //flex: 1;
      display: flex;
      width: 100%;
      text-align: center;
      .button {
        padding: 7px 0;
        &:active {
          background-color: darken($color-purple, 4) !important;
        }
        &:not(:last-child) {
        }
        border-right: solid $color-white;

        width: 100%;
        cursor: pointer;
        font-weight: weight(normal);
        &:hover {
          background-color: darken($color-purple, 3);
        }
      }
    }

    .sidebarActions {
      @include desktop() {
        min-width: 290px;
        max-width: 350px;
        flex: 3 0;
      }
      padding: 7px 0;
      height: 100%;
    }

    .toogleMobileMenu {
      @include desktop {
        display: none;
      }
      i {
        padding: 0 15px;
        &:hover {
          color: darken($color-white, 15);
        }
      }
    }
  }
}
