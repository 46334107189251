@import "../../styles/variables";
.container {
  padding: 5px;
}
.document {
  cursor: pointer;
  &:hover {
    background-color: darken($color-white, 3);
  }
  flex: 0 0 120px;
  box-shadow: 0 0 10px $color-3-grey;

  margin: 6px;

  display: flex;
  flex-direction: column;
  background-color: $color-white;
  padding: 10px 1px;
  border-radius: 9px;
  h3 {
    color: $color-orange;
  }
  p {
    font-weight: weight(lighter);
    font-size: 11px;
    color: $color-light-grey;
    margin-bottom: 10px;
  }
  i {
    margin-top: auto;
    color: $color-aqua;
    font-size: 45px;
  }
}
