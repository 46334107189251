$color-purple: #827ffe;
$color-white: white;
$color-grey: #d7cfcf;
$color-white-blue: #edf1f4;
$color-grey-border: #dedede;
$color-black-blue: #1e1e51;
$color-aqua: #1bcec1;
$color-orange: #ef5816;
$color-light-grey: #9a9a9a;
$color-shadow-white: #b9b9b9;

$color-dark-grey: #828282;
$color-1-grey: #ededed;
$color-2-grey: #dadada;
$color-3-grey: #e6e6e6;
$color-light-green: #49b717;
$font-weights: (
  "lighter": 200,
  "normal": 400,
  "bold": 600,
  "extra-bold": 800,
);
@function weight($weight-name) {
  @return map-get($font-weights, $weight-name);
}
$mobile-min-width: 640px;

@mixin mobile {
  @media (max-width: $mobile-min-width) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $mobile-min-width) {
    @content;
  }
}
$header-desktop-height: 95px;
$header-mobile-height: 20px;
