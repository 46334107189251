@import "./variables";

@mixin base {
  border: none;
  cursor: pointer;
  padding: 5px 8px;
  font-size: 16px;
  border-radius: 9px;
  color: $color-white;
  background-color: $color-purple;
  &:hover {
    filter: brightness(115%);
  }
  &:hover:disabled {
    filter: none;
  }
  &:disabled {
    cursor: auto;
    background-color: $color-light-grey;
  }
  &:disabled::after {
    content: "\f1ce";
    display: inline-block;
    font-family: "Font Awesome 5 Free";
    margin-right: 5px;
    vertical-align: middle;
    font-weight: 900;
    animation: spin 1s linear infinite;
  }
}

@mixin extra-rounded {
  @include base;
  border-radius: 20px;
  padding: 5px 15px;
}
