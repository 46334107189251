@import "../styles/containers";
@import "../styles/variables";

.sidebar {
  display: flex;
  flex-flow: column;
  & > div {
    flex: 0 1 auto;
    width: 100%;
  }
  .imageContainer {
    background-color: $color-aqua;
    display: flex;
    padding: 10px 0;

    img {
      margin: auto;
      max-width: 110px;
      @include mobile {
        max-width: 50px !important;
      }
    }
  }

  .courseInfo {
    padding: 10px 10px;
    border-bottom: solid 2px $color-purple;
    line-height: 1.25;
    h2 {
      color: $color-black-blue;
    }
    h3 {
      color: $color-light-grey;
      font-weight: weight(normal);
    }
  }

  .chaptersContainer {
    padding: 10px 10px;
  }
  .chapters {
    display: flex;
    flex-flow: column;
    flex: 1 1 auto;
    overflow: auto;
    & > div {
      width: 100%;
    }
  }
}

.chapter {
  //box-shadow: 0 0 10px $color-3-grey;
  border-radius: 9px;
  color: $color-black-blue;

  .head {
    svg {
      margin-left: 5px;
    }
    display: flex;
    padding: 5px 10px 1.2px;
    h2 {
      color: $color-black-blue;
      font-size: 22px;
    }
    border-bottom: solid 1px $color-grey;
  }
  .lessons {
    .selected {
      background-color: $color-1-grey !important;
    }
    .lesson {
      border-radius: 9px;
      cursor: pointer;
      display: flex;
      padding: 4px 10px;
      &:hover {
        background-color: $color-1-grey;
      }

      &:nth-child(even) {
      }
      .circleContainer {
        width: 10%;
        margin-left: 4px;
        .circle {
          margin-left: 5px;
          width: 27px;
          line-height: 27px;
          border-radius: 50%;
          text-align: center;
          font-size: 15px;
          background-color: $color-purple;
          color: $color-white;
        }
      }

      .locked {
        background-color: $color-light-grey !important;
      }
    }
  }
}
