@import "../../styles/inputs";
@import "../../styles/variables";
.dropdown {
  position: absolute;
  //inset: 0px 0px auto auto;
  transform: translate(-20px, 0px);
  top: 25px;
  background-color: $color-white;
  max-width: 210px;
  padding: 2px;
  border-radius: 9px;
  box-shadow: 0 0 10px $color-light-grey;
  z-index: 3;

  .item {
    cursor: pointer;
    color: #1e1e51;
    padding: 5px;
    //border-radius: 9px;
    &:not(:last-child) {
      border-bottom: solid $color-2-grey 1px;
    }
  }

  .textWithToogle {
    display: flex;
    .text {
      margin-left: 5px;
      width: 90%;
      font-size: 16px;
    }
  }
}
